.spnavigation {
  animation: spNAvigationSlideIn 0.5s forwards;
}

@keyframes spNAvigationSlideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateX(0);
  }
}
