.fav_icon {
  scale: 1;
  animation: scale 0.3s ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.ring {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(227, 41, 41, 0.5);
  animation: ring 0.3s linear;
  opacity: 0;
}

@keyframes ring {
  0% {
    width: 20px;
    height: 20px;
    opacity: 1;
    top: calc(50% - 11px);
    left: calc(50% - 10px);
  }
  100% {
    width: 40px;
    height: 40px;
    opacity: 0;
    top: calc(50% - 22px);
    left: calc(50% - 20px);
  }
}
