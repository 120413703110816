@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

html {
  font-size: 16px;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
}

* {
  box-sizing: border-box;
  line-height: 2;
}

/* @media only screen and (max-width: 768px) {
  * {
    box-sizing: border-box;
    line-height: 1.7;
  }
} */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
}

.select__container {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  max-width: 100%;
}

.select__container select {
  appearance: none;
  min-width: 150px;
  padding: 4px 8px;
  padding-right: 30px;
  font-size: 0.875rem;
  border: 1px solid var(--color-main-lightGray);
  outline: 0;
  background: #fff;
  background-image: none;
  box-shadow: none;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--color-main-dimgray);
}

.select__container::before {
  position: absolute;
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border-width: 8px 4px 0 4px;
  border-style: solid;
  border-color: var(--color-main-lightGray) transparent transparent transparent;
  content: '';
  pointer-events: none;
}

.select__container::-ms-expand {
  display: none;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

select {
  max-width: 100%;
}
